<template>
  <div>
    <article>
      <h3 class="center">歌单歌曲版权明细</h3>
      <section>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData" :key="index">
            <ul>
              <li v-for="(context, i) in option.column" :key="i">
                <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                <span v-else
                  ><a :href="item[context.prop]">{{ item[context.prop] }}</a></span
                >
              </li>
            </ul>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
  import tableData from './data/SongsAuthorizationListTabData.json';

  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        tableData: tableData,
        option: {
          column: [
            {
              label: '歌曲名',
              prop: 'SongName',
            },
            {
              label: '歌手名',
              prop: 'SingerName',
            },
            {
              label: '版权授权方',
              prop: 'Licensor',
            },
          ],
        },
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      document.title = '歌单歌曲版权明细表';
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
  }
</style>
